<!--
 * @Author: wyq
 * @Date: 2021-06-18 10:39:24
 * @LastEditTime: 2021-10-21 16:06:03
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\addNewQuestion.vue
-->
<template>
	<page-container class="page-add-new-question" asideTitle="新增试题">
		<select-form slot="aside" :form.sync="form" :questionType.sync="questionType"></select-form>
		<content-container v-if="questionType" title="试题编辑">
			<div slot="title" class="tip-title">
				打
				<span>*</span>
				为必填项
			</div>
			<component
				:is="componentName"
				:questionForm="questionForm"
				@save="submit"
				@preview="preview"
				:isReset="true"
				:submitLoading="submitLoading"
			>
				<addQuestionVideo ref="addQuestionVideo"></addQuestionVideo>
			</component>
		</content-container>
		<preview-dialog
			:form="previewForm"
			:type="questionType"
			:visiable.sync="previewVisiable"
		></preview-dialog>
		<success-dialog
			:visible.sync="dialogVisible"
			:grade="this.form.grade"
			:id="newQuestionId"
		></success-dialog>
	</page-container>
</template>
<script>
import selectForm from '../components/edit/selectForm.vue'
import singleChoiceQuestion from '../components/edit/singleChoiceQuestion.vue'
import fillsUpQuestion from '../components/edit/fillsUpQuestion.vue'
import freeResponseQuestion from '../components/edit/freeResponseQuestion'
import multipleChoiceQuestion from '../components/edit/multipleChoiceQuestion.vue'
import successDialog from '../components/edit/successDialog.vue'
import addQuestionVideo from '../components/edit/addQuestionVideo.vue'
export default {
	components: {
		selectForm,
		singleChoiceQuestion,
		fillsUpQuestion,
		freeResponseQuestion,
		multipleChoiceQuestion,
		successDialog,
		addQuestionVideo,
	},
	data() {
		return {
			form: {
				grade: 2, //年级
				subject: 2, //学科
				questionBank: 2, //所属题库
				classify: null, //教材试题分类
				specialSubjectId: null,
				labelInfos: [],
				labels: [],
				progressLabels: [],
				chapterLabels: [],
				version: null,
				knowledgePoints: [],
			},
			questionType: null,
			questionForm: {},
			newQuestionId: null,
			dialogVisible: false,
			previewVisiable: false,
			previewForm: {},
			submitLoading: false,
		}
	},
	computed: {
		componentName() {
			const map = {
				1: 'singleChoiceQuestion',
				2: 'fillsUpQuestion',
				3: 'freeResponseQuestion',
				4: 'multipleChoiceQuestion',
			}
			console.log(map[this.questionType])
			return map[this.questionType]
		},
	},
	watch: {
		form: {
			handler(newVal) {
				console.log(newVal)
			},
			deep: true,
		},
		questionType(newVal) {
			console.log(newVal)
		},
	},
	methods: {
		submit(val) {
			this.submitLoading = true
			let params = {
				...this.form,
				labels: this.form.labelInfos ? this.form.labelInfos.map((i) => i.id) : [],
				knowledgePoints: this.form.version
					? [this.form.version, ...this.form.knowledgePoints]
					: [],
				type: this.questionType,
				...val,
			}
			this.$service
				.addQuestion(params)
				.then((res) => {
					this.submitLoading = false
					this.$refs.addQuestionVideo.submitVideo(res.newQuestionId)
					this.newQuestionId = res.newQuestionId
					this.resetForm()
					this.dialogVisible = true
				})
				.catch(() => {
					this.submitLoading = false
				})
		},
		preview(val) {
			this.previewForm = val
			this.previewVisiable = true
		},
		resetForm() {
			this.questionType = null
		},
	},
}
</script>
<style lang="scss" scoped>
.page-add-new-question {
	.content-container + .content-container {
		margin-top: 24px;
	}
	.tip-title {
		display: inline-block;
		margin-left: 34px;
		color: #565656;
		font-size: 12px;
		span {
			color: #ff3838;
		}
	}
}
</style>
