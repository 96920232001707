<!--
 * @Author: wyq
 * @Date: 2021-07-06 14:27:17
 * @LastEditTime: 2021-07-07 16:34:48
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\edit\successDialog.vue
-->
<template>
  <el-dialog title="保存成功" :visible.sync="visible" width="30%">
    <!-- <span>保存成功</span> -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="goQuestionBank">进入题库</el-button>
      <el-button type="primary" @click="goDetail">查看试题</el-button>
      <el-button type="warning" @click="close">继续添加</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    grade: Number,
    id: Number,
  },
  data() {
    return {}
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit('update:visible', newVal)
      }
    },
  },
  methods: {
    goQuestionBank() {
      this.$router.push({
        name: 'QuestionBank',
        query: {
          grade: this.grade,
        },
      })
    },
    goDetail() {
      this.$router.push({
        name: 'Detail',
        query: {
          id: this.id,
          grade: this.grade,
          subject: 2,
        },
      })
    },
    close() {
      this.visible = false
    },
  },
}
</script>
<style lang="scss" scoped>
</style>