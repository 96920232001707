<!--
 * @Author: wyq
 * @Date: 2021-06-15 17:07:39
 * @LastEditTime: 2021-10-21 15:58:13
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\edit\selectForm.vue
-->
<template>
  <div class="select-form">
    <el-form ref="form" :model="form" label-width="110px" label-position="top" size="mini">
      <el-form-item label="年级">
        <el-select v-model="form.grade" :disabled="mode == 'edit'" @change="questionBankChange">
          <el-option
            v-for="item in $store.state.gradeList"
            :key="'gradeList' + item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.grade" label="学科">
        <el-select v-model="form.subject" :disabled="mode == 'edit'" @change="questionBankChange">
          <el-option
            v-for="item in $store.state.subjectList"
            :key="'gradeList' + item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.subject" label="所属题库">
        <el-select
          v-model="form.questionBank"
          :disabled="mode == 'edit'"
          @change="questionBankChange"
        >
          <el-option
            v-for="item in $store.state.questionBankList"
            :key="'gradeList' + item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <template v-if="form.questionBank == 1">
        <div class="divider"></div>
        <el-form-item label="教材版本" placeholder="不限">
          <el-select v-model="form.version" @change="versionChange">
            <el-option
              v-for="item in versionList"
              :key="'versionList' + item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="form.version">
          <el-form-item label="知识点分类">
            <el-cascader
              v-model="form.knowledgePoints"
              :options="knowledgePointsList"
              :props="cascaderProps"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="教材试题分类">
            <el-select v-model="form.classify" placeholder="不限">
              <el-option
                v-for="item in classifyList"
                :key="'gradeList' + item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <div class="divider"></div>
      </template>
      <template v-else-if="form.questionBank == 2">
        <div class="divider"></div>
        <el-form-item label="知识点标签">
          <knowledge-graph
            :grade="form.grade"
            :subject="form.subject"
            :labels.sync="form.labelInfos"
          ></knowledge-graph>
        </el-form-item>
        <el-form-item label="进度标签">
          <progress-label :grade="form.grade" :labels.sync="form.chapterLabels"></progress-label>
        </el-form-item>
        <div class="divider"></div>
      </template>
      <el-form-item v-if="validate && mode == 'add'" label="题型">
        <el-select v-model="internalQuestionType" @change="questionTypeChange">
          <el-option
            v-for="item in $store.state.typeList"
            :key="'gradeList' + item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="mode == 'edit'">
        <el-button type="primary" @click="clickSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import knowledgeGraph from '../edit/knowledgeGraph'
import progressLabel from '../edit/progressLabel'
export default {
  props: {
    mode: {
      type: String,
      default: 'add', //add 添加题目 , edit 编辑题目信息
    },
    form: {
      type: Object,
      default: () => {
        return {
          grade: null, //年级
          subject: null, //学科
          questionBank: null, //所属题库
          classify: null, //教材试题分类
        }
      },
    },
    questionType: {
      type: Number,
      default: null,
    },
  },
  components: {
    knowledgeGraph,
    progressLabel,
  },
  data() {
    return {
      internalQuestionType: null,
      versionList: [],
      knowledgePointsList: [],
      classifyList: [],
      cascaderProps: {
        value: 'id',
        label: 'label',
      },
    }
  },
  computed: {
    validate() {
      let result = false
      if (this.form.questionBank == 1) {
        result = this.form.knowledgePoints.length && this.form.classify
      } else if (this.form.questionBank == 2) {
        result = true
      }
      return result
    },
    libraryName() {
      return {
        grade: this.form.grade,
        subject: this.form.subject,
      }
    },
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          console.log('form', newVal)
          this.$emit('update:form', newVal)
        }
      },
      deep: true,
    },
    libraryName: {
      handler(val) {
        if (val.grade && val.subject) {
          if (this.mode == 'add') {
            // 清空知识点分类
            this.knowledgePointsList = []
          } else if (this.form.questionBank == 2) {
            return
          }
          this.getVersionList()
        }
      },
      deep: true,
    },
    questionType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.internalQuestionType = newVal
      }
    },
  },
  methods: {
    questionTypeChange(val) {
      this.$emit('update:questionType', val)
    },
    clickSave() {
      if (this.validate) {
        this.$emit('save')
      } else {
        this.$message({
          message: '必填试题信息还未补充完整！',
          type: 'error',
        })
      }
    },
    questionBankChange() {
      this.form.haveLabels = null
      this.form.labelInfos = []
      this.form.chapterLabels = []
      this.form.version = null
      this.form.knowledgePoints = []
      this.knowledgePointsList = []
    },
    // 获取教材版本列表
    getVersionList() {
      let params = {
        grade: this.form.grade,
        subject: this.form.subject,
      }
      this.$service.getKPList(params).then((res) => {
        this.versionList = res.book
        if (this.form.knowledgePoints.length) {
          let knowledgePoints = this.form.knowledgePoints
          const index = knowledgePoints.indexOf(0)
          if (index > -1) {
            knowledgePoints = knowledgePoints.slice(0, index)
          }
          console.log(knowledgePoints)
          this.form.knowledgePoints = knowledgePoints
          this.getKnowledgePointsList()
          this.getCategoryList()
        }
      })
    },
    versionChange() {
      this.getKnowledgePointsList()
      this.getCategoryList()
    },
    // 获取教材版本列表
    getKnowledgePointsList() {
      let params = {
        id: this.form.version,
      }
      this.$service.getKPTree(params).then((res) => {
        this.knowledgePointsList = res.tree
      })
    },
    // 获取教材试题分类列表
    getCategoryList() {
      let params = {
        bookId: this.form.version,
      }
      this.$service.getCategoryList(params).then((res) => {
        this.classifyList = res.list
      })
    },
  },
  mounted() { },
}
</script>
<style lang="scss" scoped>
.select-form {
  padding-top: 24px;
  padding-bottom: 24px;
  .el-form-item {
    padding: 0 32px;
  }
  .el-select {
    width: 190px;
  }
  ::v-deep .el-form-item__label {
    color: #999;
  }
  .divider {
    margin-left: 32px;
    margin-bottom: 18px;
    border-bottom: 1px solid #f0f0f0;
  }
}
</style>
